<script setup>
    import { inject } from 'vue';
    import utils from 'o365.modules.utils.js';
    import ODelete from 'o365.vue.components.Action.Delete.vue';
    import AttachmentsDropDown from 'o365.vue.components.Attachments.AttachmentsDropDown.vue';
    import AttachmentsConnections from 'o365.vue.components.Attachments.AttachmentsConnections.vue';

    const props = defineProps(['row', 'index', 'groupName', 'lightVersion', 'largeVersion', 'documentViewer', 'imageEditor', 'showFileNames', 'connections', 'customButtons','geoLocationBtn','geoLocationConfig']);


    const attachmentClicked = (e) => {
        e.preventDefault();
        
        if (isImage(props.row.FileName)) {
            return true;
        } else {
            if (!props.documentViewer) {
                triggerDownload(props.row, true);
            }
        }
    }

    const showImageEditing = (row) => {
        return props.imageEditor && isImage(row.FileName);
    }

    const getLink = inject('O365_AttachmentGetLink');
    const isImage = inject('O365_AttachmentIsImage');
    const getIconClasses = inject('O365_AttachmentGetIconClasses');
    const triggerDownload = inject('O365_AttachmentTriggerDownload');
    const openImageEditorByPrimKey = inject('O365_AttachmentOpenImageEditorByPrimKey');
</script>

<template>
    <template v-if="lightVersion">
        <a class="text-truncate px-2"
            style="min-width:0;"
            :title="`${row.FileName} (${row.CreatedBy} ${utils.formatDate(row.Created, 'Short Date')})`"
            :href="`${getLink(row)}?updated=${row.Updated}`"
            data-spotlightitem
            :data-primkey="row.PrimKey"
            :data-groupname="groupName"
            @click="(e) => attachmentClicked(e)">
            <span>{{row.FileName}}</span>
        </a>

        <AttachmentsDropDown :row="row" :customButtons="customButtons" :geoLocationBtn="geoLocationBtn" :geoLocationConfig="geoLocationConfig"/>
    </template>

    <template v-else-if="largeVersion">
        <div class="d-flex flex-column justify-content-between h-100">
            <AttachmentsConnections :row="row" :connections="connections"></AttachmentsConnections>

            <div class="d-flex">
                <div class="d-flex flex-column justify-content-center align-content-center text-center me-2 mb-2" style="width:140px;height:170px;">
                    <img v-if="isImage(row.FileName)" :src="getLink(row)" class="w-100 h-100" style="object-fit:contain;">
                    <i v-else :class="getIconClasses(row.FileName)" style="font-size:6rem;"></i>
                </div>

                <div class="d-flex flex-column flex-1" style="max-width:calc(100% - 150px);">
                    <label class="form-label mb-0 text-muted">{{$t('Filename')}}:</label>
                    <span class="form-control-plaintext text-truncate">{{row.FileName}}</span>
                    
                    <label class="form-label mb-0 text-muted">{{$t('Created')}}:</label>
                    <span class="form-control-plaintext text-truncate">{{utils.formatDate(row.Created, 'General Date Short Time')}}</span>

                    <label class="form-label mb-0 text-muted">{{$t('Created by')}}:</label>
                    <span class="form-control-plaintext text-truncate">{{row.CreatedBy}}</span>
                </div>
            </div>
            <div class="row">
                <div :class="showImageEditing(row) ? 'col-4' : 'col-6'">
                    <a class="btn btn-outline-primary w-100"
                        :href="`${getLink(row)}?updated=${row.Updated}`"
                        data-spotlightitem
                        :data-primkey="row.PrimKey"
                        :data-groupname="groupName"
                        @click="(e) => attachmentClicked(e)">
                        {{$t('Open')}}
                    </a>
                </div>
                <div v-if="showImageEditing(row)" class="col-4">
                    <button class="btn btn-outline-primary w-100"
                        @click="() => openImageEditorByPrimKey(row.PrimKey)">
                        {{$t('Draw on image')}}
                    </button>
                </div>
                <div :class="showImageEditing(row) ? 'col-4' : 'col-6'">
                    <ODelete :row="row" class="btn btn-outline-secondary w-100">{{$t('Delete')}}</ODelete>
                </div>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="card" style="height:100%;position:relative;">
            <AttachmentsConnections :row="row" :connections="connections"></AttachmentsConnections>

            <template v-if="isImage(row.FileName)">
                <a class="card-img-top h-100"
                    style="overflow:hidden;"
                    :title="`${row.FileName} (${row.CreatedBy} ${utils.formatDate(row.Created, 'Short Date')})`"
                    :href="`${getLink(row)}?updated=${row.Updated}`"
                    data-spotlightitem
                    :data-primkey="row.PrimKey"
                    :data-groupname="groupName"
                    @click="(e) => attachmentClicked(e)">
                    <img class="w-100 h-100" style="object-fit:contain;" :key="row.FileRef" :src="`${getLink(row)}?updated=${row.Updated}&scale=thumbnail`" />
                </a>

                <div class="d-flex justify-content-between align-items-center card-header px-2 py-1 border-bottom-0 border-top">
                    <button class="d-flex align-items-center btn btn-link text-truncate gap-1 p-0" :title="showFileNames ? row.FileName : null" @click="triggerDownload(row, true)">
                        <i class="bi bi-download"></i>
                        
                        <p class="text-truncate  p-0 mx-1 my-0" style="font-size: 0.8rem;">{{showFileNames ? row.FileName : `${$t('Image')} ${index + 1}`}}</p>
                    </button>
                    
                    <AttachmentsDropDown :row="row" :customButtons="customButtons" :geoLocationBtn="geoLocationBtn" :geoLocationConfig="geoLocationConfig"/>
                </div>
            </template>

            <template v-else>
                <a class="card-img-top d-flex justify-content-center align-items-center h-100"
                    :title="`${row.FileName} (${row.CreatedBy} ${utils.formatDate(row.Created, 'Short Date')})`"
                    :href="`${getLink(row)}?updated=${row.Updated}`"
                    data-spotlightitem
                    :data-primkey="row.PrimKey"
                    :data-groupname="groupName"
                    @click="(e) => attachmentClicked(e)">
                    <div :class="getIconClasses(row.FileName)" style="font-size:2.5rem;"></div>
                </a>

                <div class="d-flex justify-content-between align-items-center card-header px-2 py-1 border-bottom-0 border-top">
                    <button class="d-flex align-items-center btn btn-link text-truncate gap-1 p-0" :title="showFileNames ? row.FileName : null" @click="triggerDownload(row, true)">
                        <i class="bi bi-download"></i>
                        
                        <p class=" p-0 mx-1 my-0" style="font-size: 0.8rem;">{{showFileNames ? row.FileName : `${$t('Image')} ${index + 1}`}}</p>
                    </button>
                    
                    <AttachmentsDropDown :row="row" :customButtons="customButtons" :geoLocationBtn="geoLocationBtn" :geoLocationConfig="geoLocationConfig"/>
                </div>
            </template>
        </div>
    </template>
</template>